.app__video {
  height: 100vh;
  position: relative;
}

.app__video video {
  width: 100%; /* Ensures the video takes the full width of its container */
  height: 100%; /* Ensures the video takes the full height of its container */
  object-fit: cover; /* Cover the container without distorting the aspect ratio */
}

.app__video-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
}

.app__video-overlay_circle {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 1px solid var(--color-golden);
  background: none;
}
