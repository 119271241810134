@import url("https://fonts.googleapis.com/css2?family=Homemade+Apple");

.app__navbar {
  width: 100%;
  box-shadow: 1px 1px 1px goldenrod;
  background-color: black; /* Updated to set the navbar background to black */
}

.app__navbar--links a, /* Ensure the link color applies to <a> tags */
.nav-booking {
  color: #b8860b; /* Changed text color to gold */
  transition: color 0.3s ease; /* Added smooth transition for color changes */
}

.app__navbar--links a:hover,
.nav-booking:hover {
  color: darkgoldenrod; /* Darker shade of gold for hover effects */
}

.app__navbar--login .nav-booking:hover {
  border-bottom: 3px solid goldenrod;
  margin-bottom: -17px; /* Adjusted to fix the hover effect */
}

.app__navbar--logo {
  color: #b8860b; /* Set the logo text color to gold */
}

.app__navbar--seperator {
  width: 1px;
  background-color: gainsboro;
}

.app__navbar--smallscreen_overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ); /* Slightly transparent black background */
  transition: 0.5s ease;
  z-index: 5;
  font-family: var(--font-base);
}

.app__navbar--menu_btn {
  font-size: 2em;
  cursor: pointer;
  color: #b8860b; /* Set the menu button color to gold */
}
