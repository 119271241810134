/* Custom button styling for modal navigation */
.modal-nav-button {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* semi-transparent white background */
  border-radius: 50%; /* circular buttons */
  color: goldenrod; /* golden text color */
  font-size: 30px; /* larger font size for visibility */
  border: none; /* remove default border */
  width: 50px; /* set width for circle shape */
  height: 50px; /* set height for circle shape */
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-nav-button:hover {
  background-color: rgba(255, 215, 0, 0.75); /* darker gold on hover */
  color: black; /* change text color on hover */
}
